body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #289AE2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#hero-wrapper {
  flex:1;
  display: flex;
  flex-direction: row;
}
#hero-wrapper h1 {
  font-size: 3em;
}

.image-content {
  flex:1;
}

.text-content {
  flex:1;
  color:#fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

#text-wrapper {
  max-width: 450px;
}

.main-image {
  max-width: 350px;
}

@media only screen and (max-width: 767px) {
  #hero-wrapper {
    flex:1;
    display: flex;
    flex-direction: column-reverse
    
  }
}
